.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px; /* Gap between images */
  }
  
  .grid-item {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .grid-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #000;
  }
  
  .grid-item:hover .overlay {
    opacity: 0.8;
  }
  
  .text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  @media (max-width: 768px) {
    .grid {
      gap: 10px; /* Gap between images */
    }
  
  }
@import '../../assets/fonts/Fonts.css';

.hero {
  position: relative;
}

.hero-video {
  width: 100%;
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
  /* max-height: 80%; */
}

.hero-logo {
  max-width: 100%;
  height: 200px;
  margin-bottom: 0px;
}

/* .hero-description{
  font-family: 'CodeProBalck', serif;
  font-size: 30px;
} */

.hero-title {
  font-family: 'Montserrat', serif;
  font-size: 80px;
  font-weight: bold;
  color: #fff;
  /* text-transform: uppercase; */
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  white-space: nowrap;
}

.hero-subtitle {
  font-size: 60px !important;
}

.hero-description {
  color: #fff;
  letter-spacing: 1px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.contactButton {
  display: inline-block;
  background-color: #f3a3c8;
  color: black;
  padding: 10px 60px;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  margin: 0 auto;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  /* Add below properties to create a text animation */
  position: relative;
  overflow: hidden;
}

.contactButton:hover {
  transform: scale(1.1);  /* The button slightly increases its size when hovered */
  background-color: #67c1e2;
  color: #67c1e2;
}

.contactButton::before {
  content: "Contact Us!";
  position: absolute;
  color: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactButton:hover::before {
  transform: translateY(0);
}


/* Your existing styles... */

/* Responsive Styles */
@media (max-width: 1024px) {
  .hero-logo {
    height: 150px; /* you can adjust as per your need */
  }

  .hero-title {
    font-size: 40px;
  }

  .hero-description {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .hero {
    position: relative;
    overflow: hidden; /* Ensure that nothing goes outside the hero box. */
    height: 100vh; /* Set the height to the height of the viewport. */
    width: 100vw; /* Set the width to the width of the viewport. */
    overflow: hidden;
  }

  .hero-logo {
    max-width: 100%;
    height: 100px; /* reduced the height for smaller screen */
  }

  .hero-title {
    font-size: 24px;
  }

  .hero-description {
    font-size: 16px;
  }
  
  .hero-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Make the video cover the whole section. */
  }

  .contactButton {
    margin-top: 25px;
  }
}
@font-face {
  font-family: 'Higuen';
  src: url('../fonts/HiguenSerif.otf') format('opentype');
}

@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  src: local('Quicksand Regular'), local('Quicksand-Regular'),
        url('../fonts/Quicksand/Quicksand-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  src: local('Quicksand Medium'), local('Quicksand-Medium'),
        url('../fonts/Quicksand/Quicksand-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  src: local('Quicksand Bold'), local('Quicksand-Bold'),
        url('../fonts/Quicksand/Quicksand-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'CodeProBalck';
  src: url('../fonts/CodeProBlack.otf') format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Montserrat/static/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Montserrat/static/Montserrat-Bold.ttf') format('truetype');
}

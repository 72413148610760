.portfolio {
  padding: 50px;
  text-align: center;
  background-color: #333;
  color: #fff;
}

.portfolio-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  color: inherit;
}

.carousel .slide .legend {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  font-size: 18px;
  color: inherit;
}

.carousel .slide .legend a {
  color: inherit;
  text-decoration: none;
}

.carousel .slide .legend a:hover {
  color: #f3a3c8;
}

.portfolio-image {
  width: 300px;
  height: 450px;
  object-fit: contain;
}

.carousel-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 768px) {
  .portfolio {
    padding: 50px 15px !important;
  }

}
.company-numbers {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 15px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.company-numbers-title {
  font-size: 2em;
  margin-bottom: 30px;
  color: #333;
}

.numbers {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  max-width: 800px;
  gap: 100px;
}


.number-item {
  flex: 1 1 200px;
  margin: 20px;
  text-align: center;
}

.number-item span {
  display: block;
  font-size: 2.5em;
  font-weight: bold;
  color: #f3a3c8;
}

.number-item h2 {
  font-size: 1em;
  color: #333;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .numbers {
    flex-direction: column;
  }
  .number-item {
    flex: 1 1 100%;
  }
  .number-item span {
    font-size: 2em;
  }
}

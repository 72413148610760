.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}

.navbar.visible {
  opacity: 1;
  pointer-events: auto;
}

.navbar.hidden {
  opacity: 0;
  pointer-events: none;
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-logo {
  cursor: pointer;
}

.navbar-logo img {
  max-width: 150px;
  max-height: 40px;
}

.navbar-menu {
  display: flex;
  align-items: center;
}

.navbar-menu-items {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.navbar-item {
  margin-right: 10px;
}

.navbar-link {
  font-size: 14px;
  cursor: pointer;
}

.navbar-link:hover {
  color: #f3a3c8;
}

.navbar-toggle {
  display: none;
  font-size: 20px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .navbar-menu-items {
    display: none;
  }

  .navbar.active .navbar-menu-items {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #333;
    padding: 10px 0;
    margin-top: 10px;
  }
}

/* Add this media query to hide the navbar on mobile */
@media (max-width: 768px) {
  .navbar {
    display: none;
  }

  .navbar.visible {
    display: flex;
  }
}


.our-services {
    padding: 50px;
    background-image: linear-gradient(135deg, #f3a3c8, #67c1e2);
    color: #fff;
}

.our-services-content {
    text-align: center;
}

.section-title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 30px;
}

.services-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); /* Update the width of columns here */
    gap: 30px;
    justify-items: center;
  }
  

.service-card {
    width: auto;
    padding: 20px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.service-card:hover {
    transform: translateY(-5px);
}

.service-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.service-summary {
    font-size: 18px;
    margin-bottom: 10px;
    color: #666;
}

.service-description {
    font-size: 16px;
    line-height: 1.5;
    color: #555;
}

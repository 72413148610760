@import './assets/fonts/Fonts.css';

body {
    font-family: 'Quicksand', sans-serif;
    margin: 0;
}

.section-title {
    font-family: 'Montserrat', serif;
    text-transform: uppercase;
    font-size: 42px;
    font-weight: normal;
    margin-bottom: 20px;
}

.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-color: #25D366;
    border-radius: 50%;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 99999;
    transition: background-color 0.3s ease;
  }
  
  .whatsapp-button:hover {
    background-color: #128C7E;
  }
  
  .whatsapp-button svg {
    width: 30px;
    height: 30px;
    fill: #fff;
  }
  
  .section {
    position: relative;
  }
  

  
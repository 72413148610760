@import '../../assets/fonts/Fonts.css';

.about-us {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.about-us-content {
  text-align: center;
  max-width: 800px; /* set the max-width as desired */
}

.about-us-description {
  text-align: justify;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}


  .about-us-title {
    font-family: 'Higuen', serif;
    text-transform: uppercase;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  

.testimonials {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding: 50px;
}

.testimonial-card {
  position: relative;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 15px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin: 2rem;
}

.testimonial-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.testimonial-name {
  font-size: 1.2rem;
  font-weight: 700;
  color: #333;
}

.testimonial-position {
  font-size: 1rem;
  color: #777;
}

.testimonial-quote {
  font-size: 1.3rem;
  font-style: italic;
  position: relative;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .testimonials {
    padding: 20px;
  }

  .testimonial-card {
    margin: 1rem;
    padding: 1rem;
  }

  .testimonial-image {
    width: 60px;
    height: 60px;
  }

  .testimonial-name {
    font-size: 1rem;
  }

  .testimonial-position {
    font-size: 0.8rem;
  }

  .testimonial-quote {
    font-size: 1rem;
  }
}
